import axios from 'axios';

const RScrypto = require('jsrsasign');
const CryptoJS = require('crypto-js');


function useAuth() {

    const getIdUserFromUrl = () => {
        let url = window.location.search.split('token=');
        return url[url.length - 1];
    }

    const getAppToken = () => {
        return localStorage.getItem('bordas-token');
    }

    const getStrapiToken = () => {
        return localStorage.getItem('strapi-token');
    }

    const decryptMessage = (token, key) => {
        var decrypted = CryptoJS.AES.decrypt(token, CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    const getUserData = (data) => {
        localStorage.setItem("bordas-usertype", data.role);
        localStorage.setItem("bordas-userid", data.sub);
        localStorage.setItem("bordas-classes", JSON.stringify(data.classes));
        if (data.role == "professeur") {
            localStorage.setItem("teacher-students", JSON.stringify(data.students));
            localStorage.setItem("teacher-schools", JSON.stringify(data.schools));
        }
        console.log(data);
    }

    const decodeToken = (token) => {
        let jwt = decryptMessage(token, process.env.REACT_APP_SECRET);
        // Check expiration
        let jwtData = JSON.parse(atob(jwt.split(".")[1]));
        let currentTime = new Date() / 1000;

        // Check signature validity
        let isValid = RScrypto.jws.JWS.verify(jwt, process.env.REACT_APP_PUBLIC_KEY, { alg: ['RS256'] });

        // Check token expiration
        if (currentTime > jwtData.exp) {
            console.log("Token Expired")
            return false;
        } else if (!isValid) {
            console.log("Invalid Token")
            return false;
        } else {
            // Record user session info
            getUserData(jwtData);
            return true;
        }
    }

    const serverAuth = async () => {
        try {
            let token = getIdUserFromUrl();
            // If token param in URL
            if (token !== "") {
                /****HOTFIX***/
                if (token === "evidenceb-test") {
                    localStorage.setItem("bordas-token", token);
                    localStorage.setItem("bordas-usertype", "professeur");
                    localStorage.setItem("bordas-userid", "prof-test");
                    localStorage.setItem("bordas-classes", JSON.stringify([]));
                    localStorage.setItem("teacher-students", JSON.stringify([]));
                    return true
                } else {
                    //Verify token
                    let isValid = decodeToken(token);
                    if (isValid) {
                        console.log("VALID TOKEN");
                        localStorage.setItem("bordas-token", token);
                        return true;
                    } else {
                        console.log("INVALID TOKEN");
                        return false;
                    }
                }

            } else {
                // If token in cache, user can connect
                if (getAppToken()) {
                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {
            console.log("Erreur d'authentification au serveur", error);
            throw new Error(error);
        }
    }

    // const getTeacherStudents = async () =>{
    //     try {
    //         const res = await axios.get(getDataFromAmbikaUrl + getIdUserFromUrl());
    //         console.log("getTeacherStudents", res.data);

    //         if (res && res.data) {
    //             let teacher = {
    //                 isFromGar: res.data.isFromGar,
    //                 nom: res.data.nom,
    //                 prenom: res.data.prenom,
    //                 classes: res.data.classes
    //             }
    //             localStorage.setItem('teacherData', JSON.stringify(teacher));
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     } catch (error) {
    //         console.log("Erreur d'authentification au serveur", error);
    //         throw new Error(error);
    //     }
    // }

    const strapiAuth = async () => {

        if (!getStrapiToken()) {

            let params = new URLSearchParams();
            console.log('in useAuth(), process.env', process.env)
            params.append('identifier', process.env.REACT_APP_STRAPI_USER_EMAIL);
            params.append('password', process.env.REACT_APP_STRAPI_USER_PASSWORD);

            try {
                const res = await axios.post(process.env.REACT_APP_STRAPI_ENDPOINT + '/auth/local', params);
                if (res && res.data) {
                    localStorage.setItem('strapi-token', res.data.jwt);
                    return res.data.jwt;
                }
            } catch (error) {
                console.log("Erreur d'authentification au site de contenu", error);
                throw new Error(error);
            }

        } else {
            return getStrapiToken()
        }
    }

    return {
        strapiAuth,
        serverAuth
    }
}
export default useAuth;
