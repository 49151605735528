import React from "react";

export const loading = "Chargement des données...";

export const splash_intro = (
  <>
    <h1>Bienvenue dans les Modules « Espace et géométrie » avec Léonie ! </h1>
    <p>
      Les modules <strong>« Espace et Géométrie » </strong> par EvidenceB proposent des parcours adaptatifs pour une pédagogie différenciée.
      Ils permettent au professeur des écoles d'accompagner chaque élève dans l'acquisition des concepts géométriques attendus au cycle 2 à partir
  d'une compétence fondamentale : <strong>l'appréhension de l'espace. </strong>

      Ces modules adaptatifs sont co-construits par <strong>Roberto Casati</strong> chercheur CNRS spécialiste des
  questions d’orientation (Institut Jean Nicod) et <strong>Bruno Nibas</strong> (directeur de l’école élémentaire d’application Châteaudun à Amiens).
</p>
    <p> Les <strong>350 exercices</strong> pour le niveau CE2 :</p>
    <ul>
      <li>• Ciblent des difficultés relatives à <strong>l’appréhension de l’espace</strong> connues pour faciliter <strong>l’acquisition des notions structurelles en géométrie</strong></li>
      <li>• Motivent les élèves à se concentrer sur leur apprentissage grâce à une grande variété d’<strong>interfaces optimisées</strong> par des <strong>chercheurs en sciences cognitives</strong> et des <strong>experts UX</strong></li>
      <li>• S’ajustent aux niveaux des élèves au fil du parcours pour <strong>un apprentissage personnalisé</strong></li>
    </ul>
  </>
);

export const splash_entry_1 = (
  <>
    Modules
  </>
);
export const splash_entry_2 = (
  <>
    Espace pédagogique
  </>
);
export const splash_entry_3 = (
  <>
    Tableau de bord
    <br />
  </>
);


export const nav_contact_1 = "Nous contacter"
export const nav_contact_2 = "contact-sav@evidenceB.com"
export const nav_label = "Spécimen";
export const nav_entry_1 = "Accueil";
export const nav_entry_2 = "Test des modules";
export const nav_entry_4 = "Modules";
export const nav_entry_5 = "Guide pédagogique";
export const nav_entry_6 = "Espace pédagogique";
export const nav_entry_7 = "Tableau de bord";
export const nav_module = "Module";

export const home_intro = (
  <>
    <h1>Bienvenue dans les Modules « Espace et géométrie » avec Léonie ! </h1>
    <p>
      Les modules <strong>« Espace et Géométrie » </strong> par EvidenceB proposent des parcours adaptatifs pour une pédagogie différenciée.
      Ils permettent au professeur des écoles d'accompagner chaque élève dans l'acquisition des concepts géométriques attendus au cycle 2 à partir
    d'une compétence fondamentale : <strong>l'appréhension de l'espace. </strong>

      Ces modules adaptatifs sont co-construits par <strong>Roberto Casati</strong> chercheur CNRS spécialiste des
    questions d’orientation (Institut Jean Nicod) et <strong>Bruno Nibas</strong> (directeur de l’école élémentaire d’application Châteaudun à Amiens).
  </p>
    <p> Les <strong>350 exercices</strong> pour le niveau CE2 :</p>
    <ul>
      <li>• ciblent des difficultés relatives à <strong>l’appréhension de l’espace</strong> connues pour faciliter <strong>l’acquisition des notions structurelles en géométrie</strong></li>
      <li>• motivent les élèves à se concentrer sur leur apprentissage grâce à une grande variété d’<strong>interfaces optimisées</strong> par des <strong>chercheurs en sciences cognitives</strong> et des <strong>experts UX</strong></li>
      <li>• S’ajustent aux niveaux des élèves au fil du parcours pour <strong>un apprentissage personnalisé</strong></li>
    </ul>
  </>
);

export const home_entry_module = "Module";
export const home_entry_activity = "Activité";

export const activities_entry_module = "Module";
export const activities_entry_activity = "Activité";
export const activities_docLink = "Fiche pédagogique (à venir)";

export const go_to_activities_list = "Retour à la liste des activités"

export const instruction_label = "Consigne :";
export const question_label = "Question :";
export const qcm_label = "Proposition :";

export const diorama_btn = "Commencer";

export const feedback_label = "Réponse :";

export const feedback_msg_error_1 = "Oups. La bonne réponse est :";
export const feedback_msg_error_2 = "Oups. Ce n'est pas correct.";
export const feedback_msg_error_3 = "Oups. Une bonne réponse possible est :";
export const feedback_msg_error_4 = "Oups. Ce n'est pas la bonne réponse.";

export const feedback_msg_pNc = "en vert, sur l'image.";
export const feedback_msg_diorama = "la flèche verte.";
export const feedback_msg_characters_intro =
  "Quand tu as terminé, passe à la question suivante en cliquant sur le bouton ci-dessous.";
export const feedback_msg_success = "Bravo, tu as bien répondu à la question.";
export const feedback_msg_success_2 = "Bravo ! C'est la bonne réponse !";
export const feedback_msg_activity_success_1 = "Tu as réussi l'activité.";
export const feedback_msg_activity_success_2 =
  "Tu as réussi l'activité et le module est terminé !";
export const feedback_msg_next_activity = "Passe à l'activité suivante !";
export const feedback_msg_module_list = "Revenir à la liste des modules";
export const feedback_msg_improve_score = "Pour améliorer ton score";
export const feedback_msg_restart_activity = "Recommence l'activité !";
export const feedback_msg_help = "Tu n'as pas réussi toute l'activité, fais appel à ton maître ou ta maîtresse pour t'aider.";
export const feedback_msg_qcmImages = "en vert";

export const comingsoon_1 = (
  <>
    Les modules Espace et géométrie développés par EvidenceB proposent des{" "}
    <strong> parcours adaptatifs pour une pédagogie différenciée</strong>. C’est
    un assistant du professeur pour aider chaque élève.
  </>
);
export const comingsoon_2 = (
  <>
    Conçus par Roberto Casati, chercheur CNRS spécialiste des questions
    d’orientation (Institut Jean Nicod) et Bruno Nibas (directeur de l’école
    élémentaire d’application Châteaudun à Amiens), ces modules permettent de
    renforcer une{" "}
    <strong>compétence fondamentale, l’appréhension de l’espace </strong> :
    développer cette compétence, c’est faciliter pour les élèves la{" "}
    <strong>compréhension des concepts géométriques</strong> de figures et de
    solides.
  </>
);
export const comingsoon_3 = (
  <>
    Grâce à ces modules, le professeur a la possibilité de faire travailler sa
    classe en pédagogie différenciée. A la suite d’un{" "}
    <strong> test diagnostique </strong>, chaque élève réalise des{" "}
    <strong> parcours personnalisés </strong> parmi 7 modules interactifs de
    plus de 300 exercices : l’intelligence artificielle permet de lui
    sélectionner des exercices et le niveau de difficulté les mieux à même de le
    faire progresser. Un <strong>test de vérification des acquis</strong> permet
    à l’enseignant de mesurer ces progrès.
  </>
);
export const comingsoon_4 = (
  <>
    Grâce à son <strong>tableau de bord</strong>, le professeur a une vision
    claire des résultats de chaque élève et de sa classe. Il repère facilement
    les groupes de même niveau proposés par l’algorithme d’intelligence
    artificielle, ainsi que les élèves en difficulté ou en progrès. Ces
    éclairages lui permettent de faire varier le contexte d’apprentissage et les
    modalités de remédiations qu’il souhaite proposer à ses élèves.
  </>
);
export const comingsoon_title_1 = "Personnalisation du parcours des élèves";
export const comingsoon_title_2 = "Tableau de bord de l'enseignant";

// Module 0
export const feedback_msg_next_question = "Passe à la question suivante !";
export const feedback_msg_module_end = "Tu as terminé le module !";
// Module 2

export const timer_title = "Temps mis :";
export const timecomparator_pickup_order_time = "Dans l'ordre";
export const timecomparator_pickup_fastest_time = "Au plus vite";
export const graph_title_text_global = "Plan du quartier";
export const graph_title_text_local = "Plan de la classe";


// Module 6
export const validate_button = "Valider la réponse";
export const starting_button = "Commencer";

// Feedbacks module 2
export const feedback_msg_act_and_return =
  "Mission accomplie ! Tu peux comparer le chemin que tu as fait à l’aller (orange) avec celui que tu as fait au retour (vert)."; 
export const feedback_msg_reach_map_feedback =
  "Observe ton trajet en vert, puis passe à la question suivante avec la barre espace ou en cliquant sur le bouton.";
export const feedback_msg_reach_map_feedback_mobile =
  "Observe ton trajet en vert, puis passe à la question suivante en cliquant sur le bouton.";
export const feedback_msg_reach = "Bravo, tu as atteint ta destination !";
export const feedback_msg_timeout = "Le temps que tu avais est écoulé, passe à la question suivante avec la barre espace ou en cliquant sur le bouton.";
export const feedback_msg_timeout_mobile = "Le temps que tu avais est écoulé, passe à la question suivante en cliquant sur le bouton.";
export const feedback_msg_pickup_order = "Bravo ! Observe le trajet que tu as fait et le temps que tu as mis.";
export const feedback_msg_pickup_fastest = "Compare le trajet que tu as fait (vert : le plus court, gris : dans l'ordre) et le temps que tu as mis pour ramasser les cubes.";
export const feedback_msg_feedback_explore = "Tu peux apppuyer sur le bouton (sors de la scène avec la touche échap) ou appuyer sur la touche espace pour continuer.";
export const feedback_msg_feedback_explore_mobile = "Appuie sur le bouton pour continuer.";

export const feedback_msg_pickup_order_error = "Oups ! Tu n'as pas ramassé les cubes dans l'ordre.";
export const feedback_msg_pickup_fastest_error = "Oups ! Tu n'as pas ramassé les cubes suffisamment vite.";

// Module 7
export const qcmVideo_question = "Clique sur la réponse de ton choix.";
export const replay_video = "Rejouer la vidéo";
// Feedbacks module 6
export const feedback_act_and_done_error = 
  "Oups, tu n'as pas accompli ton objectif."
export const feedback_msg_reach_error =
  "Oups, tu n’as pas atteint ta destination.";
export const feedback_msg_qcm3dimage = "Bravo ! Tu as réussi à t’orienter.";
export const feedback_msg_qcm3dimage_error =
  "Oups ! Tu n’as pas réussi à t’orienter.";
export const feedback_msg_qcm3dtext = "Bravo ! Tu as choisi la bonne réponse.";
export const feedback_msg_qcm3dtext_error =
  "Oups ! Tu n’as pas choisi la bonne réponse.";


export const commencer_le_test_title = (<> <strong> Bienvenue ! </strong> </>);
export const commencer_le_test_text = (<> Léonie va te poser quelques questions pour faire connaissance. Clique sur le bouton.  </>);
export const commencer_le_parcours_title = (<> <strong> Bienvenue !  </strong></>);
export const commencer_le_parcours_text = (<> Léonie a préparé des activités rien que pour toi. Clique sur le bouton.</>);
export const continuer_le_parcours_title = (<><strong> Bienvenue ! </strong></>);
export const continuer_le_parcours_text = (<> Léonie t'attend. Clique sur le bouton.</>);
export const cest_fini = (<> <strong> C'est fini pour aujourd'hui !  </strong> </>);
export const sauvgarder_questions_title = (<> <strong> Merci d'avoir joué avec Léonie ! </strong> </>);
export const sauvgarder_questions_text = (<> Clique sur le bouton. Léonie se souviendra de tes réponses. </>);
export const quitter_prof = (<> Voulez-vous vraiment quitter ? </>);
export const quitter_student = (<> Vous êtes sur le point de vous déconnecter. Synchronisez les données de la session avant de quitter ! </>);
export const sync_data = (<> Synchroniser les données </>);
export const sync_data_success = (<> Tes réponses sont sauvegardées. À bientôt !  </>);
export const dashboard_average = (<> Taux de réussite  </>);
export const dashboard_passedtime = (<> Temps passé </>);
export const dashboard_encours = (<> L'activité est en cours </>);

//PAGE MARKETING
export const ed_section1 = "Présentation des modules"
export const ed_section2 = "Fonctionnement du tableau de bord"
export const ed_section3 = "Travailler en classe"
export const ed_section4 = "FAQ"
export const ed_section1_block1_video_title = "Présentation des modules ESPACE ET GÉOMÉTRIE avec Léonie"
export const ed_section1_block2_video_title = "Les modules Evidence B par Roberto Casati"
export const ed_downloadLink1 = "Télécharger la fiche du mode d’emploi"
export const ed_downloadLink2 = "Télécharger le guide pédagogique (à venir)"
export const ed_section1_block2 = (
  <>
    « Nous sommes des êtres capables d’évoluer dans un environnement spatial complexe et de retrouver notre chemin ou d’imaginer des trajectoires.<br /><br />
    Nous ne sommes pas des rats. Nous ne sommes pas des pigeons. Nos ancêtres ont évolué dans un environnement qui était riche en repères visuels. Nous construisons ainsi une cartographie mentale.<br /><br />
    Un exemple simple est la compréhension du triangle, par la notion basique de l'orientation&nbsp;: si vous faites un détour, vous êtes en train de tracer les deux côtés d’un triangle. Par contre, si vous faites un raccourci, vous allez tracer les deux côtés du triangle puis vous pourrez imaginer le troisième. »<br /><br />

    {/* « Le système d’orientation humain est basé sur la vision. Nous ne sommes pas des rats, vivant dans des tunnels, ni des pigeons, traversant des grandes distances en vitesse.<br />
    Nos ancêtres ont évolué dans un environnement riche en repères visuels distants (arbres isolés, montagnes, rochers) dont l’usage est intégré par notre cerveau lors de nos déplacements. »<br /><br /> */}
    <span className="italic">Roberto Casati, chercheur au CNRS, spécialiste des questions d’orientation</span>
  </>
)
export const ed_section1_block3 = "Les modules sont optimisés pour les navigateurs Google Chrome, Safari et Mozilla Firefox sur ordinateur et tablette."
export const ed_section1_block4 = "Bonne nouvelle ! Les consignes sont enregistrées. Vos élèves peuvent utiliser un casque pour les écouter. Niveau sonore de la classe contrôlé !"
export const ed_section2_title = "Fonctionnement du tableau de bord"
export const ed_section3_title = "Travailler en classe"
export const ed_section3_sub_title1 = "Comment intégrer les modules dans mon cours ?"
export const ed_section3_text1 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint ….. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
export const ed_section3_sub_title2 = "Comment organiser les sessions de travail sur les modules ?"
export const ed_section3_text2 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint ….. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
export const ed_section3_sub_title3 = "Comment suivre la progression de mes élèves ?"
export const ed_section3_text3 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint ….. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
export const ed_section3_sub_title4 = "Ressources complémentaires"
export const ed_section3_text4 = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint ….. cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
export const ed_section3_download = "Télécharger le certificat de participation"
export const ed_section4_title = "FAQ"
export const ed_section4_sub_title1 = "Est-ce que j’ai besoin d’une connexion internet ?"
export const ed_section4_text1 = 
<>
Vous aurez besoin d’une connexion internet :<br /><br />
- Pour vous connecter au site des modules « Espace et géométrie ».<br />   
- Au moment de la déconnexion de la plateforme pour que les données puissent être synchronisées et prises en compte.<br />  
- Pour accéder à l’espace pédagogique et au tableau de bord.<br /><br />  
Une fois à l’intérieur du module de votre choix, une connexion internet n’est plus nécessaire.  En cas de coupure internet, vos élèves peuvent continuer à travailler. Mais dès qu’ils quittent les modules, une connexion internet est requise pour que leurs résultats soient enregistrés.  
</>
export const ed_section4_sub_title2 = "Sur quels supports puis-je utiliser les modules ? Quelles sont les configurations requises ?"
export const ed_section4_text2 = 
<>
Les modules « Espace et géométrie » peuvent être utilisés sur ordinateur et sur tablette. Les modules sont optimisés pour les navigateurs Google Chrome, Mozilla Firefox, Safari et Microsoft Edge et pour Android et iOS. Les configurations minimales requises sont : <br /> <br /> 
Google Chrome 72 <br /> 
Mozilla Firefox 65 <br /> 
Safari 13.1.2 <br /> 
Microsoft Edge 79 <br /> 
Android Pie <br /> 
iOS 11 
</>
