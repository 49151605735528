export default (state, action) => {
  console.log("reducer USER");
  console.log("Action", action.type);
  switch (action.type) {
    case 'AUTH_STUDENT':
      return {
        id: `${action.payload.id}`,
        roles: [...action.payload.role]
      }
    case 'AUTH_TEACHER':
      console.log({
        id: `${action.payload.id}`,
        roles: [...action.payload.role],
        classes: action.payload.classes,
        schools: action.payload.schools
      })
      return {
        id: `${action.payload.id}`,
        roles: [...action.payload.role],
        classes: action.payload.classes,
        schools: action.payload.schools
      }
    default:
      return state;
  }
}