import React from 'react';


export default () => (
  <div style={{ width: '60%', margin: 'auto', textAlign: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <h1 style={{ fontSize: 54 }}>
          403 accès interdit 
        </h1>
        <iframe src="https://giphy.com/embed/Xb7VXx9yEzvtlFEM71" width="480" height="400" frameBorder="0" className="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/CBSAllAccess-season-2-i-love-lucy-episode-28-Xb7VXx9yEzvtlFEM71">via GIPHY</a></p>
        <p style={{ fontSize: 24 }}>
          Vous devez vous authentifier pour accéder à ce contenu. 
        </p>
        <p style={{ fontSize: 24 }}>
          Authentifiez vous sur le site <a href='https://www.itineraireslitteraires.fr/'> Itinéraires Littéraires </a>
          et cliquez sur le lien de la ressource evidenceB souhaitée.
        </p>
    </div>
  </div>
);
