
export const configAI = {
    endpoint: process.env.REACT_APP_XAPI_URL,
    username: process.env.REACT_APP_XAPI_USER,
    password: process.env.REACT_APP_XAPI_PASSWORD,
    allowFail: false
};

export const configDetails =  {
    endpoint: process.env.REACT_APP_XAPI_URL,
    username: process.env.REACT_APP_DETAILED_TRACKING_USER,
    password: process.env.REACT_APP_DETAILED_TRACKING_PASSWORD,
    allowFail: true
};

export const answeredVerb = {
    "id": "http://adlnet.gov/expapi/verbs/answered",
    "display": {
        "en-US": "answered"
    }
}

export const evbURI = "https://xapielements.evidenceb-services.com/";
export const adlURI = "http://adlnet.gov/expapi/verbs/";