import React, { useContext, useEffect, useState, useReducer } from 'react';
import { Tooltip } from 'antd';
import ClockIcon from "../assets/images/clock.png";
import "../scss/DashboardTable.scss"
import * as textConstant from '../constants/textContent';

const DashboardRow = ({ data, title, getSelection, detailsModule }) => {

  const calculateTrainingTime = (data) => {
    const timeMs = data.sort((a, b) => a.tms - b.tms).reduce((total, current, index, array) => {
      // Check only deltas < 5min (else probably means that user left the app)
      if (
        index > 0 &&
        (current.tms - array[index - 1].tms) / 1000 < 300 &&
        (current.tms - array[index - 1].tms) / 1000 > 0
      ) {
        const diff = (current.tms - array[index - 1].tms);
        total += diff;
      }
      return total;
    }, 0)

    // Convert milliseconds to seconds
    console.log("timeMs", timeMs)

    //const trainingTime = Math.round(parseFloat(timeMs) / 60000);  

    var minutes = Math.floor(parseFloat(timeMs) / 60000);
    var seconds = ((timeMs % 60000) / 1000).toFixed(0);
    // console.log("minutes", minutes)
    // console.log("seconds", seconds)

    const trainingTime = minutes + " min " + (seconds < 10 ? '0' : '') + seconds;
    return trainingTime;
  }

  const calculateSuccessRate = (data) => {
    const numberSuccess = data.reduce((acc, current, index) => {
      if (index === 0 && current.success) {
        acc = 1;
      } else if (index > 0 && current.success) {
        acc += 1;
      }
      return acc;
    }, 0);

    const numberAnsweredQuestions = data.length;
    // Data is in percent
    const successRate = parseFloat((numberSuccess / numberAnsweredQuestions).toPrecision(3)) * 100;
    return data.length > 0 ? successRate : 0;
  }

  const [trainingTime, setTrainingTime] = useState();
  const [successRate, setSuccessRate] = useState();

  useEffect(() => {
    console.log("Recalculating data for row", data)
    setTrainingTime(calculateTrainingTime(data));
    setSuccessRate(calculateSuccessRate(data));
  }, [data]);

  return (
    <div className="dashboard-grid" >
      <div>{title}</div>
      <div>{successRate === 0 && trainingTime === 0 ?
        <Tooltip title={textConstant.dashboard_encours}>
          <img className="clock-image" alt="clock icon" src={ClockIcon} />
        </Tooltip>
        : `${successRate} %`}</div>
      <div>{trainingTime === 0 && trainingTime === 0 ?
        <Tooltip title={textConstant.dashboard_encours}>
          <img className="clock-image" alt="clock icon" src={ClockIcon} />
        </Tooltip>
        : `${trainingTime} s`}</div>
    </div>
  )
}

export default DashboardRow