import React, { useContext, useRef, useEffect, useState, } from 'react';
import { NavLink, Redirect, withRouter, useHistory } from 'react-router-dom';

import { StoreContext } from '../Store';
import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';
import * as textConstant from '../constants/textContent';
import useRecordLocal from '../hooks/useRecordLocal';
import { Icon } from 'antd';

import "../scss/Navigation.scss";


const MainNav = () => {
  const {
    nav: {
      navModules
    },
    user: {
      user
    },
    history: {
      answers,
      dispatchAnswers
    }
  } = useContext(StoreContext);

  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { recordHistory } = useRecordLocal(user.id);
  let history = useHistory();

  const openNav = () => {
    setIsOpen(true);
  }

  const closeNav = () => {
    setIsOpen(false);
  }

  useEffect(() => {
  }, [navModules]);

  const saveAnswers = () => {
    recordHistory(answers).then(() => {
      let payload = answers;
      dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });
      console.log("answers", answers);
    });
    history.push(`/${ROUTES.EAN13}/${ROUTES.HOME}`);
  }

  const dashboardLink = () => {
    if (localStorage.getItem("bordas-token") === "evidenceb-test") {
      return (
        <a onClick={() => { alert("Pour bénéficier des fonctionnalités du tableau de bord et du suivi individualisé de vos élèves, rendez-vous sur le CNS www.cns-edu.com pour commander les licences élèves Les Maths avec Léonie CE2.\n\nElles vous sont offertes si vos élèves sont équipés du cahier. ")}} key="user test">{textConstant.nav_entry_7}</a>
      )
    } else {
      return (
        <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.DASHBOARD}`} key="dashboard">{textConstant.nav_entry_7}</NavLink>
      )
    }
  }

  return (
    <nav id="main-nav">

      <div className="mobile-menu-open" onClick={openNav}>
        <span className="material-icons">dehaze</span>
      </div>

      <div className={isOpen ? "menu-container open" : "menu-container"} ref={menuRef}>

        <div className="mobile-menu-close" onClick={closeNav}>
          <span className="material-icons">close</span>
        </div>
        <ul className="primary">
          {/* STUDENT NAV */}
          {user.roles.includes(ROLES.STUDENT) &&
            <>
              {/* <li className="nav-item">
                <NavLink to={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`}>{textConstant.nav_entry_1}</NavLink>
              </li> */}
              <li className="nav-item" id="student">
                <a onClick={() => saveAnswers()}>
                  {textConstant.nav_entry_2}
                </a>
                {/* <NavLink activeClassName="selected"  to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} ></NavLink> */}
              </li>
            </>
          }
          {/* TEACHER NAV */}
          {user.roles.includes(ROLES.TEACHER) &&
            <>
              <li className="nav-item">
                <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.SPLASH}`}>{textConstant.nav_entry_1}</NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} >{textConstant.nav_entry_2}</NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`}>{textConstant.nav_entry_4}</NavLink>
              </li>
              <li className="nav-item">
                {dashboardLink()}
              </li>
              <li className="nav-item">
                <NavLink activeClassName="selected" to={`/${ROUTES.EAN13}/${ROUTES.ESPACE}`} key="espace">{textConstant.nav_entry_6}</NavLink>
              </li>




            </>
          }
          <li className="nav-item">
            <NavLink className="logout-button" to={`/${ROUTES.EAN13}/${ROUTES.LOGOUT}`}>
              {isOpen ? "Se déconnecter" : <Icon type="poweroff" />}
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
};

export default withRouter(MainNav)