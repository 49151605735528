import { useContext } from "react";
import { useDB } from "react-pouchdb/browser";
import { getDoc, getAllDocs, createDoc, createOrUpdateDoc } from "../utils/UtilzPouchDB";
import { StoreContext } from '../Store';

function useRecordLocal(userId) {

    const {
        history: {
            dispatchAnswers
        },
    } = useContext(StoreContext);


    const dbTrackings = useDB("trackings");
    const dbHistory = useDB("history");

    const recordTracking = (payload, completionData) => {
        let date = new Date();
        let tracking = {
            module: payload.module_id,
            activity: payload.activity_id,
            question: payload.question_id,
            tms: date.toISOString(),
            isCorrect: payload.isCorrect,
            answer: payload.data ? payload.data : null,
            completionData: completionData
        }

        console.log("Question Tracking recorded", payload);

        let documentId = userId + "_" + tracking.module + "_" + tracking.activity + "_" + tracking.question;

        try {
            createDoc(dbTrackings, documentId, tracking).then((data) => {
                console.log("SetTracking localdb OK");
            });
        } catch (err) {
            console.log(err);
        }
    }


    const recordHistory = (answers) => {
        try {
            // Update history in localDB when store is updated
            const res = createOrUpdateDoc(dbHistory, userId, answers);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    const getAllHistory = async () => {
        try {
            // Retrieve user history from localdb and return it 
            const res = await getDoc(dbHistory, userId);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    const getAllTrackings = async () => {
        try {
            // Retrieve user history from localdb and return it 
            const res = await getAllDocs(dbTrackings, userId);
            return res;
        } catch (error) {
            console.log(error);
        }
    }

    return {
        recordTracking,
        getAllTrackings,
        recordHistory,
        getAllHistory
    }
}
export default useRecordLocal;