import React, { useContext, useEffect, useState, useReducer } from 'react';
import { StoreContext } from '../Store';

import { useGet } from "react-pouchdb/browser";

import DropDown from "../components/Dropdown";
import DashboardTable from "../components/DashboardTable";
import ArrowRight from "../assets/images/arrow-right.png";
import '../scss/Dashboard.scss';
import { Item } from 'rc-menu';


const Dashboard = () => {
  const {
    user: {
      user
    },
    dashboardData: {
      dashboardData,
      dispatchDashboardData
    }
  } = useContext(StoreContext);

  console.log("user", user)

  const modulesData = useGet('bordas', { id: "modules" }).data;

  const [classes, setClasses] = useState([])
  const [modules, setModules] = useState(modulesData.filter(el => el.module_id !== 0))
  const [students, setStudents] = useState([{ firstname: "", lastname: "Tous les éleves", login: "All" }])
  const [params, setParams] = useState({
    class: classes[0],
    module: { module_id: "Tous les modules", id: "All", description: "", activity_length: 0 },
    student: students[0],
    filteredData: [],
    data: user,
  });

  useEffect(() => {
    user.classes.map(el => {
      classes.push({ id: el.id, idSchool: el.idSchool, name: el.name })
    })
    user.classes[0].allStudentsInfo.map(item => {
      students.push({ firstname: item.firstname, lastname: item.lastname, login: item.login })
    })
    params.class = classes[0].name
    setParams(params)

    modules.push({ module_id: "Tous les modules", id: "All", description: "", activity_length: 0 })
  }, [])

  useEffect(() => {
    getDataWithReducer();
  }, [params])


  const getSelection = (item, name) => {
    console.log("getSelection", getSelection)
    if (name === "Classes") {
      params.class = item.name
      const studentsList = []
      studentsList.push({ firstname: "", lastname: "Tous les éleves", login: "All" })
      user.classes.find(class_name => class_name.name === item.name).allStudentsInfo.map(item => {
        studentsList.push({ firstname: item.firstname, lastname: item.lastname, login: item.login })
      })
      setStudents(studentsList)
      params.module = { module_id: "Tous les modules", id: "All", description: "", activity_length: 0 }
      params.student = { firstname: studentsList[0].firstname, lastname: studentsList[0].lastname, login: studentsList[0].login }
      setParams(params)
      getDataWithReducer()
      return item.name
    } else {
      params.module.module_id = item.module_id
      params.module.id = item.id
      params.module.description = item.name
      setParams(params)
      getDataWithReducer()
    }
  }

  const getStudentSelection = (student) => {
    params.student = { firstname: student.firstname, lastname: student.lastname, login: student.login }
    setParams(params)
    getDataWithReducer()
  }

  const getDataWithReducer = () => {
    let payload = params;
    if (params.class !== undefined) {
      if (params.student.login !== "All" && params.module.id !== "All") {
        dispatchDashboardData({ type: "GET_MODULE_OF_STUDENT", payload })
        params.filteredData = dashboardData.filteredData
      } else if (params.student.login !== "All") {
        dispatchDashboardData({ type: "GET_STUDENT", payload })
        params.filteredData = dashboardData.filteredData
      } else if (params.module.id !== "All") {
        dispatchDashboardData({ type: "GET_MODULE", payload })
        params.filteredData = dashboardData.filteredData
      } else {
        dispatchDashboardData({ type: "START", payload })
        params.filteredData = dashboardData.filteredData
        setParams(params)
      }
    }
  }

  console.log("PARAMS", params)
  console.log("modules", modules)

  return (
    user ?
      <div className="dashboard-container">
        <div className="col-placeholder"></div>
        <div className="col-left">
          <h2>École Jules Ferry</h2>
          <div className="students-container">
            {students.map(student => (
              <div key={student.login} className={params.student.lastname === student.lastname ? "student-item-selected" : "student-item"} id={student.login} onClick={() => getStudentSelection(student)}>
                {student.firstname} {student.lastname}
              </div>
            ))}
          </div>

        </div>


        <div className="col-right">
          <div className="headlines">
            <h3 className="selected-items">{params.class}</h3>
            {params.module.id === "All" ? <h3 className="selected-items">{params.module.module_id}</h3> : <h3 className="selected-items">Module: {params.module.module_id}</h3>}
          </div>
          <div className="filter-buttons">
            <DropDown name={"Classes"} content={classes} getSelection={getSelection} />
            <DropDown name={"Modules"} content={modules} getSelection={getSelection} />
          </div>
          <div className="modules-description">
            {params.module.id === "All" ?
              <h4>Tous les modules</h4> : <h4>Module {params.module.module_id} : {params.module.description}</h4>
            }
            <p>Questions réalisées : {dashboardData.filteredData ? dashboardData.filteredData.length : 0}</p>
          </div>
          {dashboardData.filteredData ?
            <DashboardTable
              data={dashboardData.filteredData}
              filterParams={params}
              getSelection={getSelection}
            /> : null}
        </div>

        <div className="col-placeholder"></div>
      </div>

      : <div> Loading </div>

  )
}

export default Dashboard;
