import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import DashboardRow from "../components/DashboardRow";

import ArrowDown from "../assets/images/arrow-down.png";
import * as textConstant from '../constants/textContent';
import "../scss/DashboardTable.scss"
import { filter } from "lodash";
import { useGet } from "react-pouchdb/browser";

const DashboardTable = ({ data, filterParams, getSelection }) => {

  const currentModule = filterParams.module.id;

  function groupByActivity(arr, property) {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  const [sortedModule, setSortedModule] = useState({})
  const [sortedActivity, setSortedActivity] = useState({})
  const [lineLength, setLineLength] = useState()

  let dataPerActivity = groupByActivity(data, "activity");
  let dataPerModule = groupByActivity(data, "module");

  const modulesData = useGet('bordas', { id: "modules" }).data;

  const retrieveInfoFromModuleAboutActivity = (activityId) => {
    return modulesData.find(module => module.id === currentModule).activities.find(activity => activity.id === activityId);
  }

  const retrieveInfoFromModule = (moduleId) => {
    return modulesData.find(module => module.id === moduleId);
  }

  useEffect(() => {
    if (dataPerModule) {
      sortingModule(dataPerModule)
    }
  }, [data])

  useEffect(() => {
    if (currentModule !== "All" && dataPerActivity) {
      sortingActivity(dataPerActivity)
    }
  }, [currentModule, data])

  const sortingModule = (array) => {
    let obj = {}
    Object.keys(array).map(key => {
      const detailsModule = retrieveInfoFromModule(key)
      obj[detailsModule.module_id] = array[key]
    });
    setSortedModule(obj)
    calculatePlaceholderLines(obj)
    return obj;
  }

  const sortingActivity = (array) => {
    let obj = {}
    Object.keys(array).map(key => {
      const detailsModule = retrieveInfoFromModuleAboutActivity(key)
      obj[detailsModule.activity_id] = array[key]
    });
    setSortedActivity(obj)
    calculatePlaceholderLines(obj)
    return obj;
  }

  console.log("DATA per ACT", dataPerActivity)
  console.log("DATA per MODULE", dataPerModule)

  console.log("sortedActivity", Object.keys(sortedActivity).length)
  console.log("sortedModule", Object.keys(sortedModule).length)

  useEffect(() => {

    console.log("lineLength", lineLength)
  }, [sortedActivity, sortedModule])

  const calculatePlaceholderLines = (array) => {
    const lineLength = `${40 - (Object.keys(array).length * 6)}vh`
    setLineLength(lineLength)
    console.log("lineLength", lineLength)
  }

  return (
    <div className="dashboardtable-container">
      <div className="grid-header">
        {currentModule === "All" ? <div className="header">Modules</div> : <div className="header">Activités</div>}
        <div className="header">{textConstant.dashboard_average}</div>
        <div className="header">{textConstant.dashboard_passedtime}</div>
      </div>
      <div className="spacer" />
      <div className="grid-content-container">
        <div className="grid-content">
          {currentModule === "All" && sortedModule && filterParams ?
            Object.keys(sortedModule).map(key => {
              const detailsModule = key
              return <DashboardRow
                data={sortedModule[key]}
                title={"Module " + key}
                getSelection={currentModule === "All" ? getSelection : null}
                detailsModule={detailsModule}
              />
            })
            : null}
          {currentModule !== "All" && sortedActivity && filterParams ?
            Object.keys(sortedActivity).map(key => {
              const detailsActivity = retrieveInfoFromModuleAboutActivity(key)
              return <DashboardRow
                data={sortedActivity[key]}
                title={"Activité - " + key}
              />
            })
            : null}
        </div>

        <div className="grid-content-placeholder" >
          <div style={{ height: lineLength }} />
          <div style={{ height: lineLength }} />
          <div style={{ height: lineLength }} />
        </div>
      </div>
    </div>
  )
}


export default DashboardTable

