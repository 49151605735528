
const findSameActivities = (array) => {
  console.log("array", array)
  if (array.length) {
    const onlyActivities = Array.from(new Set(array.map(a => a.activity))).map(activity_id => {
      return array.find(a => a.activity === activity_id)
    })
    console.log("********** onlyActivities", onlyActivities)
    return onlyActivities
  } else {
    return []
  }
}


export default (state, action) => {
  console.log('Action', action.type);
  console.log('action.payload', action.payload);
  const { payload } = action

  const filteredClass = payload.data.classes.find(item => item.name === payload.class)

  switch (action.type) {
    case 'START':
      const NotFiltered = []
      filteredClass.allStudentsInfo.map(item => { item.data.map(el => { NotFiltered.push(el) }) })
      return { filteredData: NotFiltered }
    case 'GET_MODULE':
      const filterEntireData = []
      const filterByModule = []
      filteredClass.allStudentsInfo.map(item => {
        item.data.map((el, index) => {
          filterEntireData.push(el)
        })
      })
      filterByModule.push(filterEntireData.filter(el => el.module === payload.module.id))
      return { filteredData: filterByModule[0] }
    case 'GET_STUDENT':
      console.log("GET_STUDENT", payload)
      const filterByStudent = filteredClass.allStudentsInfo.find(item => (item.login === payload.student.login));
      return { filteredData: filterByStudent.data }
    case 'GET_MODULE_OF_STUDENT':
      const preFilter = filteredClass.allStudentsInfo.find(item => (item.login === payload.student.login))
      const filterByStudentByModule = preFilter.data.filter(el => el.module === payload.module.id)
      return { filteredData: filterByStudentByModule }

  }

}