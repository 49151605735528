export default (state, action) => {
  console.log("reducer LOADING");
  console.log("Action", action.type);
  const loading = action.payload;
  switch (action.type) {
    case 'AUTH':
      return {
        ...state,
        auth: loading,
      }
    case 'STRAPI':
      return {
        ...state,
        strapi: !Object.values(loading).every(item => !item)
      }
    // case 'HISTORY':
    //   return {
    //     ...state,
    //     history: loadingBoolean
    //   }
    // case 'TRACKING':
    //   return {
    //     ...state,
    //     tracking: loadingBoolean
    //   }
    case 'RESET':
      return {
        // auth: true,
        strapi: true,
        // history: true,
        // tracking: true
      }
    default:
      return state;
  }
}