import React from 'react';
import { Spin } from 'antd';
import * as textConstant from '../constants/textContent';
import '../scss/Loading.scss'
const Loading = () => {
  return (
    <div className="loader-container">
      <div className="loader">
          <Spin tip={textConstant.loading}/>
      </div>
    </div>
  );
};

export default Loading;