import React from 'react';

import * as textConstant from '../constants/textContent';

import '../scss/Footer.scss'
import evibLogo from '../assets/images/evidenceb_logo.jpg';
import bordasLogo from '../assets/images/logo_bordas_transp.png';

import { Icon } from 'antd';

export default () => (

  <div id="footer">
    <div className="logo logo-left">
      <img src={evibLogo} />
    </div>
    <div className="center-block">
      <button className="btn">
        <a href={`mailto:${textConstant.nav_contact_2}`}>{textConstant.nav_contact_1} <Icon type="mail" /></a>
      </button>
      <ul>
        <li>© Copyright 2020 EvidenceB</li>
        <li><a href="https://www.editions-bordas.fr/sites/default/files/common/charte-donnees-personnelles-bordas.pdf" target="_blank">Charte de protection de vos données personnelles</a></li>
        <li><a href="https://www.editions-bordas.fr/sites/default/files/common/CGU.pdf" target="_blank">CGU</a></li>
      </ul>
    </div>
    <div className="logo logo-right">
      <img src={bordasLogo} />
    </div>
  </div>
);