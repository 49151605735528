import React from 'react';
import { Icon } from 'antd';

import personnalisationImg from '../assets/images/OE-Leonie-schema_adaptif.jpg';
import dashboardImg from '../assets/images/OE-Leonie-dashboard.jpg';
import * as textConstant from '../constants/textContent';
import "../scss/ComingSoon.scss";

export default () => (
    <div id="coming-soon">

        <div className="card content" >
            <div className="items">
                <div className="item">
                    <Icon type='rise' /> {textConstant.comingsoon_1}
                </div>
                <div className="item">
                    <Icon type='rise' /> {textConstant.comingsoon_2}
                </div>
                <div className="item">
                    <Icon type='rise' /> {textConstant.comingsoon_3}
                </div>
                <div className="item">
                    <Icon type='rise' /> {textConstant.comingsoon_4}
                </div>
            </div>
        </div>

        <div className="col">
            <div className="card content">
                <h2>{textConstant.comingsoon_title_1}</h2>
                <img src={personnalisationImg} />
            </div>
            <div className="card content">
                <h2>{textConstant.comingsoon_title_2}</h2>
                <img src={dashboardImg} />
            </div>
        </div>

    </div>
)
