import React, { useRef, useState, useEffect } from 'react'
import '../scss/Audio.scss'

function AudioGeneral({ voice, children }) {

    /* console.log("audioelement", voice) */

    const [audio, setAudio] = useState()

    const path = audio
    const generalAudioRef = useRef(null)

    const muteAudio = (elem) => {
        elem.muted = true;
        elem.pause();
    }

    const playAudio = (e) => {
        e.preventDefault()
        document.querySelectorAll("audio").forEach(elem => muteAudio(elem));
        generalAudioRef.current.muted = false;
        generalAudioRef.current.currentTime = 0;
        generalAudioRef.current.play();
    }
    useEffect(() => {
        setAudio(voice)
    }, [voice])

    return (
        audio ? <div className="audio-container" onClick={playAudio}>
            {children}
            <button className="btn"><span className="material-icons">volume_up</span></button>
            <audio ref={generalAudioRef} style={{ display: 'none' }} src={path}></audio>
        </div>
            : null
    )
}

export default AudioGeneral