import { configAI, configDetails, answeredVerb } from "../constants/xapi";

const TinCan = require('tincanjs');

class TinCanTracker {
    constructor(configLRS, name) {
        try {
            this.lrs = new TinCan.LRS(configLRS);
        }
        catch (error) {
            console.error("Failed to setup LRS objectm error: ", error);
            this.lrs = null;
        }
        this.name = name;
    }

    sendStatement(statement) {
        if (!this.lrs) return;
        this.lrs.saveStatement(
            statement,
            { 
                callback: (err, xhr) => {
                    if (err !== null) {
                        if (xhr !== null) {
                            console.log("Failed to save statement: " + xhr.responseText + " (" + xhr.status + ")");
                            return;
                        }
                        console.log("Failed to save statement: " + err);
                        console.log(xhr)
                        return;
                    }
                    console.log("Statement saved");
                }
            }
        );
    }

    getAllStatements(id, callback) {
        if (!this.lrs) return;
        this.lrs.queryStatements({
            params: {
                agent: new TinCan.Agent({
                    account: {
                        name: id.toString() + "-CE2",
                        homePage: process.env.REACT_APP_URL
                    },
                    objectType: "Agent"
                }),
                limit: 2147483647,
            },
            callback: callback
        });
    }

    getResultAnswer(data) {
        return {
            "extensions": {
                "http://example.com/answer": data.answer,
                "http://example.com/module": data.module,
                "http://example.com/activity": data.activity,
                "http://example.com/question": data.question
            },
            "duration": data.duration,
            "success": data.isCorrect
        }
    }

    getResult(data) {
        if (data.answer) {
            return this.getResultAnswer(data);
        }
        return {
            "extensions": data.extensions,
            "duration": data.duration,
        };
    }

    getObject() {
        return {
            "id": process.env.REACT_APP_URL + window.location.pathname,
            "objectType": "Activity"
        };
    }

    getActor(id) {
        return {
            "objectType": "Agent",
            "account": {
                "name": id.toString() + "-CE2",
                "homePage": process.env.REACT_APP_URL
            },
        };
    }

    createStatement(id, data, verb) {
        return new TinCan.Statement(
            {
                "timestamp": data.tms,
                "actor": this.getActor(id),
                "verb": verb ? verb : answeredVerb,
                "object": this.getObject(),
                "result": this.getResult(data),
            }       
        );
    }
}

export const lrsAI = new TinCanTracker(configAI, "AI");
export const lrsDetails = new TinCanTracker(configDetails, "details");