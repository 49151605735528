import React, { createContext, useEffect, useReducer, useState } from 'react';

import userReducer from './reducers/user';
import loadingReducer from './reducers/loading';
import errorReducer from './reducers/error';
import historyReducer from './reducers/history';
import gameloopReducer from './reducers/gameloop';
import dashboardReducer from './reducers/dashboard';
import { feedBackReducer, activityValidation } from './reducers/ia';

import * as Sentry from '@sentry/browser';

export const StoreContext = createContext(null);

export const StoreProvider = ({ children }) => {

  useEffect(() => {
    console.log('STORE IS MOUNTED');
    return () => console.log('STORE UNMOUNTED');
  }, []);

  // const initUser = localStorage.getItem('evidenceB-user') ?
  //   JSON.parse(localStorage.getItem('evidenceB-user')) : null;
  // const [user, storeUser] = useLocalStorage(initUser, 'user');
  const [user, dispatchUser] = useReducer(userReducer, { roles: [] });
  const [dashboardData, dispatchDashboardData] = useReducer(dashboardReducer, { filteredData: [] });

  // Connect store to apis
  const [error, dispatchError] = useReducer(errorReducer, null);
  const [loading, dispatchLoading] = useReducer(loadingReducer, { strapi: false });

  useEffect(() => {
    dispatchLoading({
      type: 'STRAPI',
      payload: {}
    });
  }, []);

  const [answers, dispatchAnswers] = useReducer(historyReducer, []);
  const [gameloop, dispatchGameloop] = useReducer(gameloopReducer, { moduleId: undefined, activityId: undefined, exerciceId: undefined, questionId: undefined });

  const [feedback, dispatchFeedback] = useReducer(feedBackReducer, '');
  /*   const [rules, dispatchRules] = useReducer(rulesReducer, { questionsPerActivity: 0 }); */

  const [answersValidity, dispatchAnswersValidity] = useReducer(activityValidation, { correct: 0, attempts: 0 });
  const [showModal, setShowModal] = useState(false);
  const [showQuestion, setShowQuestion] = useState(true);
  const [questions, setQuestions] = useState([])
  const [pool, setPool] = useState([])
  const [diagnostic, setDiagnostic] = useState([6, 7])

  const [navModules, setNavModules] = useState([])

  const [trackings, setTrackings] = useState([])
  const [lastQuestionId, setLastQuestionId] = useState()

  const store = {
    lastQuestionId: {
      lastQuestionId,
      setLastQuestionId
    },
    diagnostic: {
      diagnostic,
      setDiagnostic
    },
    nav: {
      navModules,
      setNavModules
    },
    user: {
      user,
      dispatchUser,
    },
    dashboardData: {
      dashboardData,
      dispatchDashboardData
    },
    error: {
      error,
      dispatchError
    },
    loading: {
      loading,
      dispatchLoading
    },
    history: {
      answers,
      dispatchAnswers
    },
    tracking: {
      trackings,
      setTrackings
    },
    questions: {
      questions,
      setQuestions
    },
    pool: {
      pool,
      setPool
    },
    content: {

    },
    activityValidation: {
      answersValidity,
      dispatchAnswersValidity
    },
    gameloop: {
      gameloop,
      dispatchGameloop,
    },
    ia: {
      feedback,
      dispatchFeedback,
      /*       rules,
            dispatchRules */
    },
    modal: {
      showModal,
      setShowModal
    },
    showQuestion: {
      showQuestion,
      setShowQuestion
    },
  };

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
