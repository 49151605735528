import React from "react";
import ArrowDown from "../assets/images/arrow-down.png"
import "../scss/Dropdown.scss"

const DropDown = ({ name, content, getSelection }) => {

  console.log("content", content)
  return (
    <div className="dropdown">
      <button className="btn">{name}
        <img src={ArrowDown} />
      </button>
      <div className="dropdown-content" >
        {content.map(item => (
          <div
            name={name}
            onClick={() => getSelection(item, name)}
            className="drop-item">
            {name === "Modules" ? "Module : " + item.module_id : item.name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropDown