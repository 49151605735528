import React, { useContext, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import { StoreContext } from '../Store';

import StudentImage from '../assets/images/Gabriel.jpg';
import TeacherImage from '../assets/images/leonie.jpg';
import fonctionalitesImg from '../assets/images/fonctionnalites_des_modules.jpg';

import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';
import * as textConstant from '../constants/textContent';

import '../scss/Splash.scss';

import { GET_QUESTIONS, GET_MODULES } from '../fragments/activities';
import { useQuery } from '@apollo/react-hooks';
import useApi from "../hooks/useApi";
import useRecordLocal from "../hooks/useRecordLocal";

import PouchDB from 'pouchdb-browser';
import { updateDoc } from "../utils/UtilzPouchDB";
import { lrsAI } from "../utils/tinCanTracking";
import Loading from '../components/Loading';

import isEqual from 'lodash/isEqual';


const Splash = () => {
  const {
    nav: {
      setNavModules
    },
    user: {
      user,
      dispatchUser
    },
    history: {
      dispatchAnswers
    },
    diagnostic: {
      setDiagnostic
    }
  } = useContext(StoreContext);

  const { readHistory } = useApi();
  const { recordHistory, getAllHistory } = useRecordLocal(user.id);

  const [isGraphQLDataLoaded, setIsGraphQLDataLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const modules = useQuery(GET_MODULES);
  const questions = useQuery(GET_QUESTIONS);

  let db = new PouchDB("bordas", { revs_limit: 1, auto_compaction: true });


  const createStudentsList = () => {
    let teacherStudents = JSON.parse(localStorage.getItem("teacher-students"));
    let teacherClasses = JSON.parse(localStorage.getItem("bordas-classes"));

    teacherClasses.forEach((classe) => {
      classe.allStudentsInfo = [];
      classe.allStudents.forEach((studentId) => {
        const item = teacherStudents.find(studentObj => parseInt(studentObj.id) === studentId);
        if (item) {
          classe.allStudentsInfo.push({
            firstname: item.firstname,
            lastname: item.lastname,
            login: item.login
          });
        }
      });
    });

    return teacherClasses;
  }


  const setStudentPath = (modulesPool, localModules) => {
    let path = JSON.parse(JSON.stringify(localModules));
    path.forEach(el => {
      let mod = modulesPool.find(id => el.module_id === id);
      if (mod) {
        el.isActive = true
      }
    });
    return path
  }

  let compare = (obj1, obj2) => {
    /* if ( JSON.stringify(obj1) === JSON.stringify(obj2) ){
          return true
        } else {
          return false
        } */
    return false
  }

  const setData = async (db, id, data) => {
    try {
      let res = await db.put({
        _id: id,
        data: data,
      });
      return res
    } catch (err) {
      console.log(err);
    }
  }

  let graphQlIsLoaded = () => {
    return new Promise(
      function (resolve, reject) {
        resolve({
          modules: modules.data && modules.data.modules,
          questions: questions.data && questions.data.questions
        });
      }
    );
  }

  let loadData = () => {
    if (!modules.loading && !questions.loading && !isGraphQLDataLoaded) {
      console.log('CONSOLE SPLASH No localDB')
      graphQlIsLoaded()
        .then((data) => {
          /* setNavModules(data.modules) */
          let modulesArr = data.modules;
          modulesArr.forEach(mod => {
            if (mod.module_id !== 0) {
              mod.isActive = false;
            } else {
              mod.isActive = true;
            }
          });
          setData(db, "modules", modulesArr)
            .then(() => {
              setData(db, "questions", data.questions)
                .then(() => {
                  setIsGraphQLDataLoaded(true);
                })

            })
        })
    }
  }

  /* Dispatch user info */
  useEffect(() => {
    let userType = localStorage.getItem("bordas-usertype");
    let userId = localStorage.getItem("bordas-userid");

    if (userType === ROLES.STUDENT) {
      dispatchUser({ type: "AUTH_STUDENT", payload: { id: userId, role: [userType] } });

    } else if (userType === ROLES.TEACHER) {
      // Create list of students data + retrieve trackings for all
      const studentsData = createStudentsList();

      if (studentsData.length > 0) {
        studentsData.forEach((classe) => {
          classe.allStudentsInfo.forEach((student) => {
            lrsAI.getAllStatements(student.login, function (err, result) {
              // 'err' will be null on success
              if (err !== null) {
                console.log(err);
                return;
              }
  
              console.log("TEST getStatements", result);
  
              // Remove unecessary keys from statement obj
              let filteredResults = result.statements.map(st => ({
                tms: Date.parse(st.timestamp),
                success: st.result.success,
                module: st.result.extensions["http://example.com/module"],
                activity: st.result.extensions["http://example.com/activity"],
                question: st.result.extensions["http://example.com/question"],
                isLastQuestion: st.result.extensions["http://example.com/is_last_question"],
                isLastActivity: st.result.extensions["http://example.com/is_last_activity"],
                validAnswersNeededForAct: st.result.extensions["http://example.com/valid_answers_needed"],
              }));
  
              student.data = filteredResults;
  
              // Dispatch user
              let teacherSchools = JSON.parse(localStorage.getItem("teacher-schools"));
              dispatchUser({
                type: "AUTH_TEACHER", payload: {
                  id: userId,
                  role: [userType],
                  classes: studentsData,
                  schools: teacherSchools
                }
              });
            });
          });
        });
      } else {
        // Dispatch user test
        dispatchUser({
          type: "AUTH_TEACHER", payload: {
            id: userId,
            role: [userType],
            classes: studentsData,
            schools: []
          }
        });
      }
    }
  }, []);


  /* Load GraphQL data in localDB */
  useEffect(() => {
    console.log('CONSOLE SPLASH graph', modules.loading, questions.loading, isGraphQLDataLoaded);

    db.get('modules').then(data => {
      if (!modules.loading && !questions.loading && !isGraphQLDataLoaded) {
        if (compare(modules.data.modules, data.data)) {
          console.log('CONSOLE SPLASH compare => No DB change')
          setNavModules(data.data)

        } else {
          console.log('CONSOLE SPLASH compare => DB changes')

          db.allDocs({ include_docs: true }).then(allDocs => {
            return allDocs.rows.map(row => {
              return { _id: row.id, _rev: row.doc._rev, _deleted: true };
            });
          }).then(deleteDocs => {
            return db.bulkDocs(deleteDocs);
          }).then(() => {
            loadData();
          });
        }
      }
    }).catch(err => {
      if (err.name === 'not_found') {
        loadData()
      } else {
        throw err;
      }
    })

  }, [modules.loading, questions.loading]);


  useEffect(() => {
    /*** DATA LOADING LOGIC - STUDENT SIDE ***/
    if (user && user.roles.includes(ROLES.STUDENT) && isGraphQLDataLoaded) {
      let token = localStorage.getItem("bordas-token");

      /* Look for persistence data from backend : if yes, populate store and localDB with it */
      readHistory(
        token
      ).then((result) => {

        if (result.data && result.data.data.length > 0) {
          let data = JSON.parse(result.data.data[0].state);
          console.log("state from back", data);
          let backendData = data;

          /* Look for existing persistence in localDB. Replace by backend data only if backend data > localDb data */
          let localDBData;
          getAllHistory().then((res) => {
            if (res.data) {
              let isHistoryEqual = isEqual(backendData, res.data);
              console.log("Compare backend with localDB", isHistoryEqual);
              console.log("LOCALDB", res.data)
              console.log("BACKEND", backendData)

              localDBData = res.data;

              // If arrays are not equals
              if (isHistoryEqual === false) {
                // If nb of modules is not equal
                if (res.data.length !== backendData.length) {
                  let payload = (res.data.length > backendData.length) ? res.data : backendData;

                  // Populate store with answers
                  dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });

                  // If nb of activities for last module is not equal
                } else if (res.data[res.data.length - 1].activities.length !== backendData[backendData.length - 1].activities.length) {
                  let payload = (res.data[res.data.length - 1].activities.length > backendData[backendData.length - 1].activities.length) ? res.data : backendData

                  dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });

                  // If nb of questions for last activity of last module is not equal
                } else {
                  let lastActivityPayload = backendData[backendData.length - 1].activities[backendData[backendData.length - 1].activities.length - 1];
                  let lastActivityLocalDB = res.data[res.data.length - 1].activities[res.data[res.data.length - 1].activities.length - 1];

                  if (lastActivityLocalDB.questions.length !== lastActivityPayload.questions.length) {
                    let payload = (lastActivityLocalDB.questions.length > lastActivityPayload.questions.length) ? res.data : backendData;

                    dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });

                  } else {
                    let payload = backendData;
                    // Populate LocalDB with backend answers
                    recordHistory(payload ).then(() => {
                      dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });
                    });
                  }
                }
              } else {
                let payload = backendData;
                recordHistory(payload ).then(() => {
                  dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });
                });
              }
            } else {
              let payload = backendData;
              recordHistory(payload ).then(() => {
                dispatchAnswers({ type: "SET_ANSWERS_HISTORY", payload });
              });
            }


            // If module 0 is already finished - deactivate and set modules pool
            let modulesPool;
            if (backendData[0].hasOwnProperty("pool")) {
              modulesPool = backendData[0].pool;
            } else if (localDBData && localDBData[0].hasOwnProperty("pool")) {
              modulesPool = localDBData[0].pool;
            }

            if (modulesPool !== undefined) {
              setDiagnostic(modulesPool);

              // Activate modules
              let db = new PouchDB("bordas");
              console.log(modulesPool)
              db.get('modules').then(data => {
                let path = setStudentPath(modulesPool, data.data);
                updateDoc(db, "modules", path)
                  .then(() => {
                    setIsLoaded(true);
                  });
              });
            } else {
              setIsLoaded(true);
            }
          });
        } else {
          setIsLoaded(true);
        }
      }).catch((error) => {
        console.log(error);
      });
      /*** DATA LOADING LOGIC - TEACHER SIDE ***/
    } else if (user && user.roles.includes(ROLES.TEACHER) && isGraphQLDataLoaded) {
      // Create list of students data + retrieve trackings for all
      const payload = createStudentsList();
      payload.forEach((classe) => {
        classe.allStudentsInfo.forEach((student) => {
          lrsAI.getAllStatements(student.login, function (err, result) {
            // 'err' will be null on success
            if (err !== null) {
              console.log(err);
              return;
            }

            console.log("TEST getStatements", result);

            // Remove unecessary keys from statement obj + remove duplicates if any (in case they've been sent twice or more to the LRS)
            let filteredResults = result.statements.map(st => ({
              tms: st.timestamp,
              success: st.result.success,
              module: st.result.extensions["http://example.com/module"],
              activity: st.result.extensions["http://example.com/activity"],
              question: st.result.extensions["http://example.com/question"],
             })).reduce((acc, o) => {
              if (!acc.some(obj => 
                obj.activity === o.activity && 
                obj.module === o.module &&
                obj.question === o.question &&
                obj.tms === o.tms 
              )) {
                acc.push(o);
              }
              return acc;
            }, []);

            student.data = filteredResults;

            // Dispatch data in store user
            console.log("NEW teacher data", payload);
            dispatchUser({ type: "TEACHER_CLASSES", payload });
          });
        });
      });

      setIsLoaded(true);
    }
  }, [isGraphQLDataLoaded]);

  const dashboardLink = () => {
    if (localStorage.getItem("bordas-token") === "evidenceb-test") {
      return (
        <a onClick={() => {
          alert("Pour bénéficier des fonctionnalités du tableau de bord et du suivi individualisé de vos élèves, rendez-vous sur le CNS www.cns-edu.com pour commander les licences élèves Les Maths avec Léonie CE2.\n\nElles vous sont offertes si vos élèves sont équipés du cahier. ")}} key="user test">
          <div className="user-item card interactive">
            <h3>{textConstant.splash_entry_3}</h3>
            <img src={StudentImage} width="150px" />
          </div>
        </a>
      )
    } else {
      return (
        <Link to={`/${ROUTES.EAN13}/${ROUTES.DASHBOARD}`}>
          <div className="user-item card interactive">
            <h3>{textConstant.splash_entry_3}</h3>
            <img src={StudentImage} width="150px" />
          </div>
        </Link>
      )
    }
  }


  const contentTeacher = <div className="splash-container centered">
    <div className="title-container card" style={{ maxWidth: '80%' }}>
      {textConstant.splash_intro}
    </div>
    <div className="users-container">
      {/* <Link to={`/${ROUTES.EAN13}/${ROUTES.HOME}`}>
        <div className="user-item card interactive"> 
          <h3>{textConstant.splash_entry_1}</h3>
          <img src={StudentImage} width="150px" />
        </div>
      </Link> */}
      <Link to={`/${ROUTES.EAN13}/${ROUTES.HOME_RESSOURCES}`}>
        <div className="user-item card interactive">
          <h3>{textConstant.splash_entry_1}</h3>
          <img src={TeacherImage} width="150px" />
        </div>
      </Link>

      {dashboardLink()}

      <Link to={`/${ROUTES.EAN13}/${ROUTES.ESPACE}`}>
        <div className="user-item card interactive">
          <h3>{textConstant.splash_entry_2}</h3>
          <img src={fonctionalitesImg} width="150px" />
        </div>
     </Link>
    </div>
  </div>


  const contentStudent = <div className="splash-container centered">
    <div className="title-container card" style={{ maxWidth: '80%' }}>
      {textConstant.splash_intro}
    </div>
    <div className="users-container">
      <Link to={`/${ROUTES.EAN13}/${ROUTES.HOME}`}>
        <div className="user-item card interactive">
          <h3>{textConstant.splash_entry_1}</h3>
          <img src={StudentImage} width="150px" />
        </div>
      </Link>
    </div>
  </div>


  if (!user.id || (user.roles && user.roles.includes(ROLES.TEACHER))) {
    return (
      <>
        {
          !isLoaded ? <Loading /> : contentTeacher
        }
      </>
    )
  } else if (user.roles && user.roles.includes(ROLES.STUDENT)) {
    return (
      <>
        {
          !isLoaded ? <Loading /> : <Redirect to={`/${ROUTES.EAN13}/${ROUTES.HOME}`} />
        }
      </>
    )
  }
};

export default Splash;