const checkIfItemExists = (itemsArray, item_id) => {
    let index = itemsArray.findIndex(item => item.id === item_id)
    if (index >= 0) {
        return index
    }
    return false
}


const addModule = (payload) => {
    return {
        id: payload.module_id,
        activities: [
            {
                id: payload.activity_id,
                pool: payload.pool,
                questions: [
                    {
                        id: payload.question_id,
                        isCorrect: payload.isCorrect,
                        data: payload.data
                    }
                ]
            }
        ]
    }

}

const addActivity = (payload) => {
    return {
        id: payload.activity_id,
        pool: payload.pool,
        questions: [
            {
                id: payload.question_id,
                isCorrect: payload.isCorrect,
                data: payload.data
            }
        ]
    }
}

const addQuestion = (payload) => {
    return {
        id: payload.question_id,
        isCorrect: payload.isCorrect,
        data: payload.data
    }
}

export default (state, action) => {
    console.log('Action', action.type);
    switch (action.type) {
        case 'ADD_ANSWER':
            let modulIndex, activityIndex, questionIndex;
            modulIndex = checkIfItemExists(state, action.payload.module_id)
            // check if module exists
            if (modulIndex === false) {
                state.push(addModule(action.payload))
                console.log('CONSOLE HISTORY', action.payload, state)
                return [...state]
            } else {
                // check if activity exists
                activityIndex = checkIfItemExists(state[modulIndex].activities, action.payload.activity_id)
                if (activityIndex === false) {
                    state[modulIndex].activities.push(addActivity(action.payload))
                    console.log('CONSOLE HISTORY', action.payload, state)
                    return [...state]
                } else {
                    // check if question exists
                    questionIndex = checkIfItemExists(state[modulIndex].activities[activityIndex].questions, action.payload.question_id)
                    if (questionIndex === false) {
                        state[modulIndex].activities[activityIndex].questions.push(addQuestion(action.payload))
                        console.log('CONSOLE HISTORY', action.payload, state)
                        return [...state]
                        // if True update question data
                    } else {
                        state[modulIndex].activities[activityIndex].questions[questionIndex].id = action.payload.question_id;
                        state[modulIndex].activities[activityIndex].questions[questionIndex].isCorrect = action.payload.isCorrect;
                        state[modulIndex].activities[activityIndex].questions[questionIndex].data = action.payload.data;
                        console.log('CONSOLE HISTORY', action.payload, state)
                        return [...state]
                    }
                }
            }
        case 'ADD_MODULES_POOL':
            console.log("ADD_MODULES_POOL")
            state[0].pool = action.payload.pool;
            state[0].completed = true;
            return [...state]
        case 'SET_MODULE_COMPLETED':
            state.find(mod => mod.id === action.payload.id).completed = true;
            console.log('CONSOLE HISTORY module completed', state)
            return [...state]
        case 'SET_ACTIVITY_COMPLETED':
            console.log("SET_ACTIVITY_COMPLETED");
            state.find(mod => mod.id === action.payload.data.module.id).activities.find(act => act.id === action.payload.data.activity.id).completed = true;
            console.log('CONSOLE HISTORY activity completed', state)
            return [...state]
        case 'SET_ANSWERS_HISTORY':
            console.log("SET_ANSWERS_HISTORY")
            // In this case, state array is entirely retrieved from backend of localDB
            return action.payload
        case 'RESET_ACTIVITY_ANSWERS':
            console.log("RESET_ACTIVITY_ANSWERS")
            let modIndex = state.findIndex(item => item.id === action.payload.data.module.id);
            let actIndex = state[modIndex].activities.findIndex(activity => activity.id === action.payload.data.activity.id);
            state[modIndex].activities.splice(actIndex, 1)
            return [...state]
        case 'RESET_ANSWERS':
            return []
    }

}