import React from 'react';
import { Anchor } from 'antd';
import * as textConstant from '../constants/textContent'
import '../scss/EducationalSpace.scss';
import presentation from '../assets/images/educationSpace/presentation-des-modules.jpg'
import browserIcons from '../assets/images/educationSpace/browsers-icons.png'
import soundIcon from '../assets/images/educationSpace/sound-icon.png'
import prizeIcon from '../assets/images/educationSpace/prize-icon.png'
import dashboard from '../assets/images/educationSpace/tableau_bord.jpg'
import leonieIcon from '../assets/images/educationSpace/leonie-icon.png'
import certificat from '../assets/certificat-de-participation.pdf'
import modEmploi from '../assets/mode-d-emploi.pdf'
import video_1 from '../assets/demonstration-modules.mp4'
import video_2 from '../assets/roberto-casati-les-modules-evidenceb.mp4'

const EducationalSpace = () => {

  const { Link } = Anchor;

  return (
    <div id="educationalSpace">
      <nav>
        <Anchor>
          <Link href="#anchor1" title={textConstant.ed_section1} />
          <Link href="#anchor2" title={textConstant.ed_section2} />
          {/* <Link href="#anchor3" title={textConstant.ed_section3} /> */}
          <Link href="#anchor4" title={textConstant.ed_section4} />
        </Anchor>
      </nav>
      <section id="section1">
        <div className="anchors" id="anchor1"></div>
        <h1>{textConstant.ed_section1}</h1>
        <div className="container">
          <div className="block">
            <video controls src={video_1}></video>
            <span className="video_title">{textConstant.ed_section1_block1_video_title}</span>
            <div className="links">
              <a className="btn" href={modEmploi} target="_blank">{textConstant.ed_downloadLink1}</a>
              <button className="btn disabled" disabled>{textConstant.ed_downloadLink2}</button>
            </div>
          </div>
          <div className="block">
            <video controls src={video_2}></video>
            <span className="video_title">{textConstant.ed_section1_block2_video_title}</span>
            <p>{textConstant.ed_section1_block2}</p>
          </div>
        </div>
        <div className="container">
          <img src={presentation} />
        </div>
        <div className="container">
          <div className="block">
            <img src={browserIcons} />
            <p>{textConstant.ed_section1_block3}​</p>
          </div>
          <div className="block">
            <img src={soundIcon} />
            <p>{textConstant.ed_section1_block4}</p>
          </div>
        </div>
      </section>

      <section id="section2">
        <div className="anchors" id="anchor2"></div>
        <h1>{textConstant.ed_section2}</h1>
        <img src={dashboard} />
      </section>
      {/* <section id="section3">
        <div className="anchors" id="anchor3"></div>
        <h1>{textConstant.ed_section3_title}</h1>
        <h2>{textConstant.ed_section3_sub_title1}​</h2>
        <p>{textConstant.ed_section3_text1}</p>
        <h2>{textConstant.ed_section3_sub_title2}</h2>
        <p>{textConstant.ed_section3_text2}</p>
        <h2>{textConstant.ed_section3_sub_title3}​</h2>
        <p>{textConstant.ed_section3_text3}</p>
        <h2>{textConstant.ed_section3_sub_title4}</h2>
        <p>{textConstant.ed_section3_text4}</p>
        <div className="block">
          <a className="btn" href={certificat} target="_blank">{textConstant.ed_section3_download}</a>
          <img src={prizeIcon} />
        </div>
      </section> */}
      <section id="section4">
        <div className="anchors" id="anchor4"></div>
        <h1>{textConstant.ed_section4_title}</h1>
        <h2>{textConstant.ed_section4_sub_title1}</h2>
        <p>{textConstant.ed_section4_text1}</p>
        <h2>{textConstant.ed_section4_sub_title2}</h2>
        <p>{textConstant.ed_section4_text2}</p>
      </section>
      <img src={leonieIcon} />
    </div>

  )
}

export default EducationalSpace;
