
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

export default (state, action) => {

    const { history } = action.payload;

    switch (action.type) {
        case 'SET_MODULE':
            console.log('SET_MODULE New state', {
                ...state,
                ...action.payload
            });
            return {
                ...state,
                ...action.payload
            }
        case 'SET_EXERCICE':
            console.log('SET_EXERCICE New state', {
                ...state,
                ...action.payload
            });
            return {
                ...state,
                ...action.payload
            }
        case 'NEXT_QUESTION':
            console.log('NEXT_QUESTION New state', {
                ...state,
                questionId: state.questionId + 1
            });
            if (history) {
                if (action.payload.role === ROLES.TEACHER) {
                    history.push(`/${ROUTES.EAN13}/module-${state.moduleId}-ressource/activite-${state.activityId}/image-${state.exerciceId}/question-${state.questionId + 1}`);
                }
                if (action.payload.role === ROLES.STUDENT) {
                    history.push(`/${ROUTES.EAN13}/module-${state.moduleId}/activite-${state.activityId}/question-${state.questionId + 1}`);
                }

                return {
                    ...state,
                    questionId: state.questionId + 1
                }
            } else {
                return {
                    ...state,
                }
            }
        case 'NEXT_EXERCICE':
            console.log('NEXT_EXERCICE New state', {
                ...state,
                questionId: 1,
                exerciceId: state.exerciceId + 1
            });
            if (history) {
                if (action.payload.role === ROLES.TEACHER) {
                    history.push(`/${ROUTES.EAN13}/module-${state.moduleId}-ressource/activite-${state.activityId}/image-${state.exerciceId + 1}/question-1`);
                }
                if (action.payload.role === ROLES.STUDENT) {
                    history.push(`/${ROUTES.EAN13}/module-${state.moduleId}/activite-${state.activityId}/question-1`);
                }
                return {
                    ...state,
                    questionId: 1,
                    exerciceId: state.exerciceId + 1
                }
            } else {
                return {
                    ...state
                }
            }
        case 'NEXT_ACTIVITY':
            console.log('NEXT_ACTIVITY New state', {
                ...state,
                questionId: 1,
                exerciceId: 1,
                activityId: state.activityId + 1
            });
            if (history) {
                history.push(`/${ROUTES.EAN13}/module-${state.moduleId}/activite-${state.activityId + 1}/question-1`);
                return {
                    ...state,
                    questionId: 1,
                    exerciceId: 1,
                    activityId: state.activityId + 1
                }
            } else {
                return {
                    ...state
                }
            }
        case 'RESET_ACTIVITY':
            if (history) {
                history.push(`/${ROUTES.EAN13}/module-${state.moduleId}/activite-${state.activityId}/question-1`);
                return {
                    ...state,
                    questionId: 1,
                    exerciceId: 1,
                    activityId: state.activityId
                }
            } else {
                return {
                    ...state
                }
            }
    }
}